import React from "react";
import Layout from "@/components/Layout";
import { graphql, Link } from "gatsby";
import Helmet from "react-helmet";
import SEO from "@/helpers/SEOHelper.js";
import ReactMarkdown from "react-markdown";

import ContactButton from "../components/ContactButton";

export const ForConsultantsTemplate = ({ data }) => {
  const pageData = data.markdownRemark.frontmatter;
  const seo = pageData.seo;
  return (
    <>
      <Helmet>
        <meta name="description" content={SEO.description(seo.description)} />
        <meta property="og:title" content={SEO.title(seo.title)} />
        <meta
          property="og:description"
          content={SEO.description(seo.description)}
        />
        <meta property="og:image" content={SEO.image(seo.image)} />
        <title>{SEO.title(seo.title)}</title>
      </Helmet>

      <div className="min-h-screen">
        <section className="pt-48 pb-48">
          <div className="flex justify-center">
            <ReactMarkdown
              source={pageData.hero}
              className="max-w-6xl mx-auto markdown-content text-center"
            />
          </div>
        </section>
        <section className="mb-40">
          <div className="container flex flex-col lg:flex-row items-center justify-between pt-3 pb-3">
            <div className="flex flex-col justify-center items-center lg:items-start lg:justify-start flex-1 pt-20 pb-20">
              <h2 className="mb-6">{pageData.qualificationsTitle}</h2>
              <ReactMarkdown
                source={pageData.qualificationsParagraph}
                className="markdown-content mb-10 text-black text-center lg:text-left leading-6"
              />
              <ContactButton lgJustify="start" />
            </div>
            <div className="flex justify-center lg:justify-end items-center flex-1 self-stretch ">
              <div
                className="bg-black w-full h-full min-h-card max-w-card shadow-cardRed bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${pageData.qualificationsImage.publicURL})`,
                }}
              ></div>
            </div>
          </div>
        </section>

        <section className="mb-40">
          <div className="container flex flex-col lg:flex-row items-center justify-between pt-3 pb-3">
            <div className="flex justify-center lg:justify-start items-center flex-1 self-stretch ">
              <div
                className="max-h-card max-w-card  min-h-card bg-black w-full h-full shadow-cardRed bg-cover bg-center bg-no-repeat"
                style={{
                  backgroundImage: `url(${pageData.consultingImage.publicURL})`,
                }}
              ></div>
            </div>
            <div className="flex flex-col justify-center items-center lg:items-start lg:justify-start flex-1 pt-20 pb-20">
              <h2 className="mb-6">{pageData.consultingTitle}</h2>
              <ReactMarkdown
                source={pageData.consultingParagraph}
                className="markdown-content mb-10 text-black text-center lg:text-left leading-6"
              />
              <ContactButton lgJustify="start"/>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

const ForConsultants = ({ data }) => {
  return (
    <Layout hideNav={true}>
      <ForConsultantsTemplate data={data} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query ForConsultantsPageTemplate {
    markdownRemark(frontmatter: { template: { eq: "forConsultants" } }) {
      frontmatter {
        title
        hero

        qualificationsParagraph
        qualificationsImage {
          publicURL
        }
        consultingTitle
        consultingParagraph
        consultingImage {
          publicURL
        }
        seo {
          description
          title
        }
      }
    }
  }
`;

export default ForConsultants;
